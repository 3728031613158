import React, { useEffect, useState, useRef, useContext } from "react"
// import Layout from "components/common/layout.js"
// import { getJson } from "utils"
import InputTitle from "components/tools/InputTitle.js"
import Header from "./header.js"
import * as styles from "./index.module.css"
import { useRefObj, useAddress, useMenu } from "hooks"
import { scbType, scbMemberType } from "utils/data"
import api from "apis"
import { navigate } from "gatsby"
import { numReg } from "utils/memberUtils.js"
const Index = () => {
  const [scbInfo, setScbInfo] = useState(null)
  useEffect(() => {
    ;(async () => {
      const scbInfo = await api.getscbDetail()

      setScbInfo(scbInfo)

      await errorHandler(scbInfo)
    })()
  }, [])

  const [inputRefs, setInputRefs] = useRefObj({
    name: null,
    number: null,
    addressDetail: null,
    address: null,
  })
  const { scb, teacher } = scbType

  const [onAddress, address] = useAddress()

  const [placeState, setPlace, onPlace] = useMenu()
  const [occState, setOcc, onOcc] = useMenu()

  const [isTeacher, setToggle] = useState(false)

  //
  const color = {
    red: "2px solid #E95C63",
    gray: "1px solid #dfdfdf",
  }
  const setSelect = (menu, color) => {
    menu.style.border = color
  }

  const onPlaceType = ({ currentTarget }) => {
    setPlace(currentTarget)

    onPlace(curr => {
      setSelect(curr, color.red)
      setToggle(curr.getAttribute("id") === "sch")
      return prev => setSelect(prev, color.gray)
    })
  }

  const onOccType = ({ currentTarget }) => {
    setOcc(currentTarget)

    onOcc(curr => {
      setSelect(curr, color.red)
      return prev => setSelect(prev, color.gray)
    })
  }

  const onNext = async () => {
    if (await errorHandler(scbInfo)) return

    const { address, addressDetail, name, number } = inputRefs.current
    console.log(placeState.current?.dataset)

    if (!(address.value && addressDetail.value && name.value && number.value)) {
      alert("필수입력란을 모두 입력해주세요.")
      return
    }

    if (!placeState.current?.dataset?.id) {
      alert("유형을 선택해주세요.")
      return
    }

    if (
      placeState.current?.dataset?.id === "2" &&
      (occState.current?.dataset?.id === undefined ||
        occState.current?.dataset?.id === "0")
    ) {
      alert("학교회원은 교사유형도 선택해주세요.")
      return
    }

    if (!numReg(number.value)) {
      alert("올바른 휴대폰 번호를 입력하세요.")
      return
    }

    const subcribe_table = {
      subscribe_type: placeState.current.dataset.id,
      subscribe_teacher: occState.current?.dataset?.id || "0",
      subscribe_name: name.value,
      subscribe_phone: number.value,
      subscribe_address: address.value + addressDetail.value,
      subscribe_memo: "",
    }

    const result = await api.addScb({
      body: subcribe_table,
    })

    if (result) {
      navigate("/subscription/payments")
    } else {
      alert("잠시후 다시 시도해주세요")
    }
  }

  return (
    <div className={styles.con}>
      <Header title={"구독하실 분의 정보를 입력해 주세요."} />
      <div>
        <InputTitle name={"구독자 유형"} titleSize={16} />
        <div className={styles.selectBox}>
          {Array.from(scb).map(([key, { id, img, name }]) => (
            <div onClick={onPlaceType} key={id} data-id={key} id={id}>
              <img src={img} />
              <div>{name}</div>
            </div>
          ))}
        </div>
        <div
          className={styles.selectBoxSub}
          style={{ display: isTeacher ? "flex" : "none" }}
        >
          {Array.from(teacher).map(([key, val], i) => (
            <div onClick={onOccType} key={i} data-id={key}>
              <div>{val}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.text}>
        <InputTitle name={"구독자 이름"} titleSize={16} />
        <input
          type="text"
          placeholder={"이름입력"}
          id={"name"}
          style={{ fontSize: "14px", fontWeight: "400" }}
          ref={setInputRefs}
        />
      </div>
      <div className={styles.text}>
        <InputTitle name={"구독자 연락처"} titleSize={16} />
        <input
          type="text"
          placeholder={"휴대폰 번호 입력"}
          id={"number"}
          style={{ fontSize: "14px", fontWeight: "400" }}
          ref={setInputRefs}
        />
        <div className={styles.guide}>
          구독 결제 안내 및 확인을 위해 정확한 휴대폰 번호를 입력해주세요.
        </div>
      </div>
      {/*주소*/}

      <div className={styles.address}>
        <InputTitle name={"구독자 주소"} titleSize={16} />
        <div className={styles.inputBox}>
          <input
            type="text"
            readOnly
            value={address}
            id={"address"}
            style={{ fontSize: "14px", fontWeight: "400" }}
            ref={setInputRefs}
          />
          <div
            className={styles.redBtn}
            style={{
              height: "45px",
              fontWeight: "400",
            }}
            onClick={onAddress}
          >
            주소 검색
          </div>
        </div>
        <input
          type="text"
          placeholder={"상세 주소 입력"}
          id={"addressDetail"}
          style={{ fontSize: "14px", fontWeight: "400" }}
          ref={setInputRefs}
        />
      </div>
      {/* <div className={styles.text}>
        <InputTitle name={"메모"} titleSize={16} />
        <input type="text" id={"memo"} ref={setInputRefs} />
      </div> */}

      <div
        className={styles.redBtn}
        style={{
          margin: "130px 0 20px 0",
        }}
        onClick={onNext}
      >
        다음
      </div>
    </div>
  )
}

export default Index

const errorHandler = async scbInfo => {
  if (!scbInfo) {
    return false
  } else if (!scbInfo.owner) {
    if (await confirm("단체회원은 그룹장만이 정보를 입력할수있습니다")) {
      navigate(-1)
    }
    return true
  } else if (scbInfo.isProfile) {
    if (await confirm("구독자 정보가 저장되어 있습니다.")) {
      navigate("/subscription/payments/")
    }
    return true
  }
  return false
}
